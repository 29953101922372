import Web3 from 'web3'
import seckill_abi from '../utils/json/seckill.json'
import bberc20_abi from '../utils/json/bberc20.json'
import USDT_abi from '../utils/json/USDT.json'
import store from '../store'

// 取总数量
export const USDTbalanceOf = async (address) => {
    if(store.state.daou.chainId == "TRC"){
        console.log("TRC",address,store.state.daou.usdtaddress)
        const tronWeb =  window.tronWeb;
        const contracts =  await tronWeb.contract().at(store.state.daou.usdtaddress);
        const eggW = await contracts.balanceOf(address).call()
        return eggW;
    }else {
        try {
            const contract = initContractsusdt().refContract.methods
            const eggW = await contract.balanceOf(address).call()
            return eggW;
        } catch (error) {
            throw new Error(error);
        }
    }

}

// usdt授权
export const USDTapprove = async (amount,at_address,address) => {
    if(store.state.daou.chainId == "TRC"){
        console.log("amount",amount)
        const tronWeb =  window.tronWeb;
        const contract= await tronWeb.contract().at(store.state.daou.usdtaddress);
        const eggW = await contract.approve(at_address,amount).send({from: address})
        return eggW;
    }else {
        try {
            const contract = initContractsusdt().refContract.methods
            const eggW = await contract.approve(at_address,amount).send({from: address})
            return eggW;
        } catch (error) {
            throw new Error(error);
        }
    }
}

// 地址获取
export const getAccounts = async () => {
    if(store.state.daou.chainId == "TRC"){
        const tronWeb =  window.tronWeb;
        const eggW  = tronWeb.defaultAddress.base58;
        return eggW;
    }else {
        let web3 =store.state.daou.web3Modal
        const eggW = await web3.eth.getAccounts()
        return eggW[0];
    }
}




//seckill
export const initContracts = () => {
    let web3 = new Web3(window.ethereum)
    return {
        web3,
        refContract: new web3.eth.Contract(seckill_abi, '0xdbe5f19ce309aacb72aff44e524926627ca026e4'),
    }
}

//USDT初始化
export const initContractsusdt = () => {

    // let web3 = new Web3(window.ethereum)
    let web3 =store.state.daou.web3Modal
    let usdtaddress = store.state.daou.usdtaddress
    return {
        web3,
        refContract: new web3.eth.Contract(USDT_abi, usdtaddress),

    }
}
//cxerc20授权专用
export const initContractserc = () => {
    let web3 = new Web3(window.ethereum)
    return {
        web3,
        refContract: new web3.eth.Contract(bberc20_abi, '0xF7b7841a8D6dd45E6617eAAE42E1172820df7Ef2'),

    }
}
